import { BottomSheet, Illustration } from "@clipboard-health/ui-components";
import { Text, Title } from "@clipboard-health/ui-react";
import { type UseModalState } from "@clipboard-health/ui-react";
import { DialogContent, Stack } from "@mui/material";
import { Button } from "@src/appV2/redesign/components/Button";
import { DialogFooter } from "@src/appV2/redesign/components/DialogFooter";

interface InstantReviewShiftDocumentSubmissionErrorBottomSheetProps {
  modalState: UseModalState;
  onClick: () => void;
}

export function InstantReviewShiftDocumentSubmissionErrorBottomSheet(
  props: InstantReviewShiftDocumentSubmissionErrorBottomSheetProps
) {
  const { modalState, onClick } = props;

  return (
    <BottomSheet
      modalState={modalState}
      footer={
        <DialogFooter
          orientation="vertical"
          onClose={() => {
            modalState.closeModal();
          }}
        >
          <Button fullWidth size="large" onClick={onClick}>
            Try again
          </Button>
        </DialogFooter>
      }
    >
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          paddingX: 4,
          paddingTop: 9,
        }}
      >
        <Stack alignItems="center" spacing={8} sx={{ px: 4 }}>
          <Illustration type="document-rejected" />
          <Title variant="h2" component="h3" sx={{ textWrap: "balance", textAlign: "center" }}>
            Something went wrong
          </Title>
          <Text align="center">
            An issue occurred while submitting your document. Please try again or wait a few minutes
            and try again later.
          </Text>
        </Stack>
      </DialogContent>
    </BottomSheet>
  );
}
