import { type HcpRequirement } from "@src/appV2/Accounts/Documents/types";

interface IsDocumentInstantReviewProps {
  document: HcpRequirement;
}

export function isDocumentInstantReview(props: IsDocumentInstantReviewProps) {
  const { document } = props;
  return Boolean(
    document.visibleToHCP &&
      document.isAutomatedVerificationDecision === true &&
      document.helloSignConfig?.isEmbeddedFlowEnabled
  );
}
