import { getSafeAreaPaddings } from "@clipboard-health/ui-theme";
import { Box, ThemeProvider } from "@mui/material";
import { getTheme } from "@src/appV2/lib/Theme/theme";
import { ShiftBlockPage } from "@src/appV2/ShiftBlocks/Page";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";

export function ShiftBlocksPage() {
  const worker = useDefinedWorker();

  return (
    <ThemeProvider theme={getTheme("light")}>
      <Box
        sx={{
          ...getSafeAreaPaddings({ top: true }),
        }}
      >
        <ShiftBlockPage agent={worker} />
      </Box>
    </ThemeProvider>
  );
}
