import { Pill, type PillSize } from "@clipboard-health/ui-components";
import pluralize from "pluralize";

interface ShiftDocumentsPillProps {
  missingDocumentsCount: number;
  /**
   * If true, the pill will be compact and show a shorter label
   */
  compact?: boolean;
  size?: PillSize;
}

export function ShiftDocumentsPill(props: ShiftDocumentsPillProps) {
  const { missingDocumentsCount, compact = false, size = "small" } = props;

  return (
    <Pill
      size={size}
      label={
        compact
          ? missingDocumentsCount
          : `${missingDocumentsCount} ${pluralize("document", missingDocumentsCount)} needed`
      }
      backgroundColor={(theme) => theme.palette.intent?.neutral.background}
      color={(theme) => theme.palette.intent?.neutral.text}
      iconColor={(theme) => theme.palette.intent?.neutral.icon}
      iconType={compact ? "document" : undefined}
      sx={{
        width: "fit-content",
      }}
    />
  );
}
