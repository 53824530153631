import { type HcpRequirement } from "@src/appV2/Accounts/Documents/types";

import { isDocumentInstantReview } from "../isDocumentInstantReview";

interface IsShiftInstantReviewProps {
  missingDocuments: HcpRequirement[];
}

export function isShiftInstantReview(props: IsShiftInstantReviewProps) {
  const { missingDocuments } = props;
  return (
    missingDocuments.length > 0 &&
    missingDocuments.every((document) =>
      isDocumentInstantReview({
        document,
      })
    )
  );
}
