import { Pill } from "@clipboard-health/ui-components";

export function QuickSignPill() {
  return (
    <Pill
      size="medium"
      label="Quick Sign"
      backgroundColor={(theme) => theme.palette.intent?.success.background}
      color={(theme) => theme.palette.intent?.success.text}
      iconColor={(theme) => theme.palette.intent?.success.icon}
    />
  );
}
