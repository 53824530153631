import { CbhFeatureFlag, useCbhFlag } from "../../../FeatureFlags";

export function useIsShiftBlocksEnabled() {
  const shiftBlockBookingFlag = useCbhFlag(CbhFeatureFlag.ROLLOUT_SHIFT_BLOCK_BOOKING, {
    defaultValue: {
      isBlockBookingEnabled: false,
    },
  });

  return shiftBlockBookingFlag.isBlockBookingEnabled;
}
