import { BottomSheet, LoadingSpinner } from "@clipboard-health/ui-components";
import { Text, Title } from "@clipboard-health/ui-react";
import { type UseModalState } from "@clipboard-health/ui-react";
import { DialogContent, Stack } from "@mui/material";

interface SubmittingDocumentDialogProps {
  modalState: UseModalState;
}

export function SubmittingDocumentDialog(props: SubmittingDocumentDialogProps) {
  const { modalState } = props;

  return (
    <BottomSheet
      modalState={modalState}
      paperSx={{
        minHeight: (theme) => theme.size!.bottomSheet.loading.minHeight,
      }}
    >
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          paddingX: 4,
        }}
      >
        <Stack direction="column" alignItems="center" spacing={8} sx={{ px: 4 }}>
          <LoadingSpinner size="xlarge" variant="secondary" />
          <Title variant="h2" component="h3" sx={{ textWrap: "balance", textAlign: "center" }}>
            Submitting Document
          </Title>
          <Text variant="body1" align="center">
            Should only take a few seconds
          </Text>
        </Stack>
      </DialogContent>
    </BottomSheet>
  );
}
