import { WorkerRequirementStatus } from "@clipboard-health/contract-worker-app-bff";
import { BottomSheet, Illustration } from "@clipboard-health/ui-components";
import { Text, Title, type UseModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { DialogContent, Stack } from "@mui/material";
import { DocumentStatus } from "@src/appV2/Accounts/Documents/types";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { BookabilityDecision, type BookabilityStatusItem } from "@src/appV2/OpenShifts/ShiftAction";
import { Button } from "@src/appV2/redesign/components/Button";
import { DialogFooter } from "@src/appV2/redesign/components/DialogFooter";
import { isRequirementInstantReview } from "@src/appV2/redesign/Documents/utils/isRequirementInstantReview";
import {
  SHIFT_DISCOVERY_SHIFT_HELLO_SIGN_CONTAINER_MODAL_PATH,
  SHIFT_DISCOVERY_SHIFT_MODAL_PATH,
} from "@src/appV2/redesign/ShiftDiscovery/paths";
import { type DocumentSubmittedModalRoutePathParams } from "@src/appV2/redesign/ShiftDiscovery/types";
import { useShiftModalsDataContext } from "@src/appV2/redesign/ShiftDiscovery/useShiftModalsDataContext";
import pluralize from "pluralize";
import { useParams } from "react-router-dom";

import { ShiftBottomSheetSkeleton } from "../../../BottomSheet/SheetSkeleton";

interface InstantReviewShiftDocumentSubmittedBottomSheetProps {
  modalState: UseModalState;
  bookabilityStatus?: BookabilityStatusItem;
}

export function InstantReviewShiftDocumentSubmittedBottomSheet(
  props: InstantReviewShiftDocumentSubmittedBottomSheetProps
) {
  const { modalState, bookabilityStatus } = props;

  const { shiftId, requirementId, documentStatus } =
    useParams<DocumentSubmittedModalRoutePathParams>();
  const { navigateToModal, blockingRequirements, isLoadingShiftModalsData } =
    useShiftModalsDataContext();

  const isDocumentApproved = documentStatus === DocumentStatus.APPROVED;

  const visibleBlockingDocuments = blockingRequirements?.filter(
    (requirement) => requirement.visibleToHCP === true
  );
  const inReviewBlockingDocuments = visibleBlockingDocuments?.filter(
    (requirement) => requirement.status === WorkerRequirementStatus.IN_REVIEW
  );
  const inReviewBlockingDocumentsCount = inReviewBlockingDocuments?.length ?? 0;

  const nonInReviewBlockingDocuments = visibleBlockingDocuments?.filter(
    (requirement) => requirement.status !== WorkerRequirementStatus.IN_REVIEW
  );
  const nonInReviewBlockingDocumentsCount = nonInReviewBlockingDocuments?.length ?? 0;

  const instantReviewBlockingDocuments = nonInReviewBlockingDocuments?.filter((requirement) =>
    isRequirementInstantReview({
      workerRequirement: requirement,
    })
  );
  const isAnyBlockingDocumentInstantReview = (instantReviewBlockingDocuments?.length ?? 0) > 0;
  const areAllBlockingDocumentsInstantReview =
    (instantReviewBlockingDocuments?.length ?? 0) === visibleBlockingDocuments?.length;

  function getContent() {
    if (nonInReviewBlockingDocumentsCount > 0) {
      return `Complete ${nonInReviewBlockingDocumentsCount} ${
        areAllBlockingDocumentsInstantReview ? "instant review" : "more"
      } ${pluralize("document", nonInReviewBlockingDocumentsCount)} before confirming your shift.`;
    }

    if (inReviewBlockingDocumentsCount > 0) {
      return `You have documents that are pending review before you can book this shift.`;
    }

    if (bookabilityStatus?.attributes.bookability.decision === BookabilityDecision.ALLOWED) {
      return "You can proceed to book your shift.";
    }

    return "Please complete the additional requirements to book this shift.";
  }

  return (
    <BottomSheet
      modalState={modalState}
      footer={
        <DialogFooter
          orientation="vertical"
          onClose={() => {
            modalState.closeModal();
          }}
        >
          {!isLoadingShiftModalsData && (
            <Button
              fullWidth
              size="large"
              onClick={() => {
                logEvent(
                  APP_V2_USER_EVENTS.SHIFT_BOOKING_FLOW_DOCUMENT_SUBMISSION_REVIEW_CTA_CLICKED,
                  {
                    shiftId,
                    submittedRequirementId: requirementId,
                    submittedDocumentStatus: documentStatus,
                    remainingBlockingDocumentsCount: visibleBlockingDocuments?.length,
                    isNextDocumentInstantReview: isAnyBlockingDocumentInstantReview,
                    remainingInstantReviewDocumentsCount: instantReviewBlockingDocuments?.length,
                    nextInstantReviewDocumentRequirementId: isAnyBlockingDocumentInstantReview
                      ? instantReviewBlockingDocuments?.[0].requirementId
                      : undefined,
                  }
                );
                if (
                  isAnyBlockingDocumentInstantReview &&
                  isDefined(instantReviewBlockingDocuments?.[0])
                ) {
                  navigateToModal(
                    SHIFT_DISCOVERY_SHIFT_HELLO_SIGN_CONTAINER_MODAL_PATH,
                    {
                      requirementId: instantReviewBlockingDocuments?.[0]?.requirementId,
                      shiftId,
                    },
                    true
                  );
                } else {
                  navigateToModal(SHIFT_DISCOVERY_SHIFT_MODAL_PATH, { shiftId }, true);
                }
              }}
            >
              {isAnyBlockingDocumentInstantReview ? "Complete next document" : "Continue"}
            </Button>
          )}
        </DialogFooter>
      }
    >
      {isLoadingShiftModalsData ? (
        <ShiftBottomSheetSkeleton />
      ) : (
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            paddingTop: 9,
          }}
        >
          <Stack alignItems="center" spacing={7}>
            <Illustration type={isDocumentApproved ? "document-approved" : "document-review"} />
            <Title variant="h2" component="h3" sx={{ textWrap: "balance", textAlign: "center" }}>
              {isDocumentApproved ? "Your document is approved!" : "Your document is under review"}
            </Title>
            <Text align="center" paddingX={4} variant="body1">
              {getContent()}
            </Text>
          </Stack>
        </DialogContent>
      )}
    </BottomSheet>
  );
}
